import React from 'react';
import leftArrowB from "./images/tabler_arrow-left (2).svg";

const NextArrow = ({onClick}) => {
    return (
        <div className="clickk" onClick={onClick} style={{position:"absolute", zIndex:"999",backdropFilter:"blur(22.5px)",left:"20px",width:"50px", height:"50px",background:"rgba(255, 255, 255, 0.50)", padding:"10px", borderRadius:"50%", marginRight:"20px", display:"flex", alignItems:"center", justifyContent:"center"}}>
            <img src={leftArrowB} style={{height:"100%", width:"100%"}} alt=""/>
        </div>
    );
};

export default NextArrow;