import React from 'react';
import rightArrow from "./images/tabler_arrow-left (3).svg";

const PrevArrow = ({onClick}) => {
    return (
        <div className="ckickk" onClick={onClick} style={{right: "0px", zIndex: "999",backdropFilter: "blur(22.5px)" , position:"absolute",width:"50px", height:"50px",background:"rgba(255, 255, 255, 0.50)", padding:"10px", borderRadius:"50%", marginRight:"20px", display:"flex", alignItems:"center", justifyContent:"center"}}>
            <img src={rightArrow} style={{height:"100%", width:"100%"}} alt=""/>
        </div>
    );
};

export default PrevArrow;