import React from 'react';

const ImgP = ({active, setActive, children}) => {
    return (
        <div className ={active ? "modal active" : "modal"} onClick={() => setActive(false)}>
            <div className = "modal_content imgp1" onClick={e => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );
};

export default ImgP;