import home from "./images/tabler_home.svg"
import call from "./images/tabler_phone-call.svg"
import cal from "./images/cal.png"
import emailjs from '@emailjs/browser';
import rect from "./images/Rectangle 9.png"
import n1 from "./images/n1.jpg"
import rect2 from "./images/Rectangle 6.png"
import rect3 from "./images/Rectangle 7.png"
import rect4 from "./images/Rectangle 10.png"
import rect5 from "./images/Rectangle 8.png"
import rect6 from "./images/Rectangle 10 (1).png"
import rect7 from "./images/Rectangle 11.png"
import rect8 from "./images/Rectangle 12.png"
import bud4 from "./images/bud4.jpg"
import rect9 from "./images/Rectangle 13.png"
import krest from "./images/akar-icons_cross.svg"
import rect11 from "./images/Rectangle 11 (1).png"
import rect12 from "./images/Rectangle 12 (1).png"
import rect13 from "./images/Rectangle 13 (1).png"
import rect14 from "./images/Rectangle 14.png"
import rect15 from "./images/Rectangle 15.png"
import q from "./images/3.png"
import w from "./images/4.png"
import e from "./images/5.png"
import r from "./images/6.png"
import rect16 from "./images/Rectangle 9 (1).png"
import test from "./images/test.png"
import rect17 from "./images/Rectangle 8 (1).png"
import rect18 from "./images/Rectangle 7 (1).png"
import rect19 from "./images/Rectangle 9 (2).png"
import rect20 from "./images/Rectangle 10 (2).png"
import rect21 from "./images/Rectangle 11 (2).png"
import arrow from "./images/Button arrow.png"
import arrow1 from "./images/Button arrow (1).png"
import tg from "./images/tabler_brand-telegram.png"
import plus from "./images/tabler_plus.png"
import plan1 from "./images/plan1.png"
import plan2 from "./images/plan2.png"
import plan3 from "./images/plan3.png"
import plan4 from "./images/plan4.png"
import leftArrow from "./images/tabler_arrow-left.svg"
import rightArrow from "./images/tabler_arrow-right.svg"
import leftArrowB from "./images/tabler_arrow-left (2).svg"
import rightArrowB from "./images/tabler_arrow-left (3).svg"
import a from "./images/a.jpg"
import zq from "./images/z.jpg"
import xq from "./images/x.jpg"
import cq from "./images/c.jpg"
import vq from "./images/v.jpg"
import bq from "./images/b.jpg"
import nq from "./images/n.jpg"
import mq from "./images/m.jpg"
import lq from "./images/l.jpg"
import b from "./images/b.png"
import c from "./images/c.png"
import d from "./images/d.png"
import p from "./images/8.jpg"
import g from "./images/9.png"

import g11 from "./images/111r.jpg"
import g22 from "./images/222r.jpg"
import g33 from "./images/333r.jpg"
import g44 from "./images/444r.jpg"
import g55 from "./images/555r.jpg"
import g66 from "./images/666r.jpg"
import g77 from "./images/777r.jpg"
import g88 from "./images/888r.jpg"
import qqw from "./images/qwww.png"
import qqww from "./images/wsxw.png"

import pov1 from "./images/1pov.png"
import pov1r from "./images/1povr.png"
import pov2 from "./images/2pov.png"
import pov2r from "./images/2povr.png"
import pov3 from "./images/3pov.png"
import pov3r from "./images/3povr.png"

import pov12 from "./images/1pov2.jpg"
import pov12r from "./images/1povr2.png"
import pov22 from "./images/2pov2.png"
import pov22r from "./images/2povr2.png"
import pov32 from "./images/ppppp.jpg"
import pov32r from "./images/3povr2.png"


import gallmob1 from "./images/gallmob1.png"
import gallmob2 from "./images/gallmob2.png"
import gallmob3 from "./images/gallmob3.png"
import gallmob4 from "./images/gallmob4.png"
import gallmob5 from "./images/gallmob5.png"
import gallmob6 from "./images/gallmob6.png"
import gallmob7 from "./images/gallmob7.png"
import gallmob8 from "./images/gallmob8.png"

import p2 from "./images/p2.png"
import p3 from "./images/p3.png"
import p4 from "./images/p4.png"
import p5 from "./images/p5.png"
import p6 from "./images/p6].png"
import p7 from "./images/p7.jpg"
import p8 from "./images/p8.png"

import h from "./images/h.svg"

import povvv1v2 from "./images/1povvv2v.png"
import povvv2v2 from "./images/2povvv2v.png"
import podvvv2 from "./images/podvvvv2v.png"

import et2v22 from "./images/ett2v22.png"

import et1v1m from "./images/1v1etm.png"
import et2v1m from "./images/1v2etm.png"
import podv1m from "./images/1vpod.png"
import et1v2m from "./images/2v1etm.png"
import et2v2m from "./images/2v2etm.png"
import podv2m from "./images/2vpod.png"




import pov1v1 from "./images/1pov1v.png"
import pov2v1 from "./images/2pov1v.png"
import podv1v from "./images/podv1v.png"
import pov1v2 from "./images/1pov2v.png"
import pov2v2 from "./images/2pov2v.png"
import podv2v from "./images/podv2v.png"

import newbud from "./images/newbud.png"
import newbuddesk from "./images/newbuddesk.png"

import g1 from "./images/1g.png"
import g2 from "./images/2g.png"
import g3 from "./images/3g.png"
import g4 from "./images/4g.png"
import g5 from "./images/5g.png"
import g6 from "./images/6g.png"
import g7 from "./images/7g.png"
import g8 from "./images/8g.png"
import ban1 from "./images/Banner1.png"
import ban2 from "./images/Banner2.png"
import ban3 from "./images/Banner3.png"
import Logo from "./images/Logo.svg"
import './App.css';
import Slider from "react-slick";
import "./slick.css";
import "./slick-theme.css";
import SliderJs from "./SliderJs";
import Popup from "./Popup";
import {useEffect, useRef, useState} from "react";
import NextArrow from "./NextArrow";
import PrevArrow from "./PrevArrow";
import ImagePopUp from "./ImagePopUp";
import ImgP from "./ImgP";
import {motion, useScroll, } from "framer-motion"
function App() {



let u = 0
let [plan, setPlan] = useState(1)
let [plan1, setPlan1] = useState(1)
    console.log()
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        nextArrow:<PrevArrow/>,
        prevArrow:<NextArrow/>



    }
    const settings2 = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow:<PrevArrow/>,
        prevArrow:<NextArrow/>
    }
    const settings3 = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    }
    const settings4 = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow:<PrevArrow/>,
        prevArrow:<NextArrow/>
    }
    const settings5 = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        focusOnSelect:false
    }
    const settings6 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow:<PrevArrow/>,
        prevArrow:<NextArrow/>,
        focusOnSelect:false
    }
    const settings7 = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow:<PrevArrow/>,
        prevArrow:<NextArrow/>,
        focusOnSelect:false
}

    let [ba, setBa] = useState(0)

    const settings8 = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow:<PrevArrow/>,
        prevArrow:<NextArrow/>,
        focusOnSelect:false
}




    const [active, setActive] = useState(false)
    const [activ1, setActiv1] = useState(false)
    const [active1, setActive1] = useState(false)
    const [active2, setActive2] = useState(false)
    const [active3, setActive3] = useState(false)
    const [active4, setActive4] = useState(false)
    const [active5, setActive5] = useState(false)
    const [active6, setActive6] = useState(false)
    const [active7, setActive7] = useState(false)
    const [active8, setActive8] = useState(false)
    const [active9, setActive9] = useState(false)

    const [mobActive1, setActiveMob1] = useState(false)
    const [mobActive2, setActiveMob2] = useState(false)
    const [mobActive3, setActiveMob3] = useState(false)
    const [mobActive4, setActiveMob4] = useState(false)
    const [mobActive5, setActiveMob5] = useState(false)
    const [mobActive6, setActiveMob6] = useState(false)
    const [mobActive7, setActiveMob7] = useState(false)
    const [mobActive8, setActiveMob8] = useState(false)
    const ref = useRef(null)
    const {scrollYProgress} = useScroll({
        target:ref,
        offset:["0 1", "1 1"]
    })


    const [number, setNumber] = useState("+380")
    let [ban, setBan] = useState(0)

    const textAnimation = {
    hidden: {
        x: -1000,
        opacity: 0,
    },
    visible:{
        x: 0,
        opacity:1,
        transition:{delay:0.1, duration: 0.5}
    }
    }
    const textAnimation1 = {
        hidden: {
            x: 1000,
            opacity: 0
        },
        visible:{
            x: 0,
            opacity:1,
            transition:{delay:0.1, duration: 0.5}
        }
    }
    const scrollRef = useRef(null)
    const futureAnimation = {
    hidden: {},
        visible:{}
    }




    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_vut3nyw', 'template_s0qamty', form.current, '0i-u5DE9JWlKCEF9h')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };








    useEffect(() => {
        let q = document.querySelector(".wsx")
        let t = document.querySelector(".ckickk")
        let t1 = document.querySelector(".blur")
        t1.innerHTML = "ТВІЙ НОВИЙ ДІМ"
        t.addEventListener("click", () => {

            setBan(ban++)
            if (ban % 1 === 0  && ban % 2 !== 0 && ban % 3 !== 0){
                q.classList.add("anim")
                setTimeout(() => {
                    q.classList.remove("anim")
                }, 500)
                setTimeout(() => {
                    t1.innerText = "ПРИБУДИНКОВА ТЕРИТОРІЯ"
                }, 250)

            }else if (ban % 2 === 0 && ban % 3 !== 0){

                q.classList.add("anim")
                setTimeout(() => {
                    q.classList.remove("anim")
                }, 500)
                setTimeout(() => {
                    t1.innerText = "ОСОБИСТИЙ ДВОРИК"
                }, 250)
            }else {
                q.classList.add("anim")
                setTimeout(() => {
                    q.classList.remove("anim")
                }, 500)
                setTimeout(() => {
                    t1.innerText = "ТВІЙ НОВИЙ ДІМ"
                }, 250)
                setBan(ban--)
                setBan(ban--)
                setBan(ban--)
            }
            console.log(ban)
            console.log(ban % 3, ban % 2, ban % 1)

        })
        let mm = document.querySelector(".clickk")
        mm.addEventListener("click", () => {

            setBan(ban--)
            if (ban % 1 === -0 && ban % 2 !== -0 && ban % 3 !== -0){
                q.classList.add("anim")
                setTimeout(() => {
                    q.classList.remove("anim")
                }, 500)
                setTimeout(() => {
                    t1.innerText = "ОСОБИСТИЙ ДВОРИК"
                }, 250)
            }else if (ban % 2 === -0 && ban % 3 !== -0){
                q.classList.add("anim")
                setTimeout(() => {
                    q.classList.remove("anim")
                }, 500)
                setTimeout(() => {
                    t1.innerText = "ПРИБУДИНКОВА ТЕРИТОРІЯ"
                }, 250)
            }else {
                q.classList.add("anim")
                setTimeout(() => {
                    q.classList.remove("anim")
                }, 500)
                setTimeout(() => {
                    t1.innerText = "ТВІЙ НОВИЙ ДІМ"
                }, 250)
                setBan(ban++)
                setBan(ban++)
                setBan(ban++)
            }
            console.log(ban)
            console.log(ban % 3, ban % 2, ban % 1)
        })

    }, [])
    window.addEventListener("scroll", () => {
        console.log(window.scrollY)
        if(window.innerWidth > 1600){
            document.querySelector(".gallery1").style.cssText = `transform:translate(${(window.scrollY - 7000)/220}%)`
            document.querySelector(".gallery").style.cssText = `transform:translate(-${(window.scrollY - 5500)/220}%)`
        }else{
            document.querySelector(".gallery1").style.cssText = `transform:translate(${(window.scrollY - 7000)/50}%)`
            document.querySelector(".gallery").style.cssText = `transform:translate(-${(window.scrollY - 5500)/50}%)`
        }
      })

    return (

        <div className="App">
            <script src = "https://smtpjs.com/v3/smtp.js"></script>
            <a name = "top"></a>

            <ImgP active={active7} setActive={setActive7}>
                <div style={{position:"relative"}}>
                    <img src={krest} className = "krest" style={{position:"absolute", top:"3px", right:"0px"}} onClick={() => {
                        setActive7(false)
                    }}/>
                </div>
                <img src={h} className="galka"/>
                <h3 style={{width:"100%", textAlign:"center", fontSize:"20px"}}>Ваш запит прийнято</h3>
                <p style={{width:"100%"}}>
                    Найближчим часом менеджер зв`яжеться з вами
                </p>
                <div style={{display: "flex", justifyContent: "center", margin: "20px 0 0 0"}}>

                    <button className="call_button" style={{marginBottom:"15px", display: "flex", justifyContent: "center"}} onClick={() => {
                        setActive7(false)
                    }}>
                        На головну
                    </button>
                </div>
            </ImgP>

            <ImagePopUp active={active1} setActive={setActive1}>
                <div style={{position:"relative"}}>
                    <img src={krest} className = "krest" style={{position:"absolute", top:"3px", right:"0px"}} onClick={() => {
                        setActive2(false)
                    }}/>
                </div>
                <img src={g33} style={{maxWidth:"100%"}}/>
            </ImagePopUp>
            <ImagePopUp active={active2} setActive={setActive2}>
                <div style={{position:"relative"}}>
                    <img src={krest} className = "krest" style={{position:"absolute", top:"3px", right:"0px"}} onClick={() => {
                        setActive2(false)
                    }}/>
                </div>
                <img src={g11} style={{maxWidth:"100%"}}/>
            </ImagePopUp>
            <ImagePopUp active={active3} setActive={setActive3}>
                <div style={{position:"relative"}}>
                    <img src={krest} className = "krest" style={{position:"absolute", top:"3px", right:"0px"}} onClick={() => {
                        setActive2(false)
                    }}/>
                </div>
                <img src={g44} style={{maxWidth:"100%"}}/>
            </ImagePopUp>
            <ImagePopUp active={active4} setActive={setActive4}>
                <div style={{position:"relative"}}>
                    <img src={krest} className = "krest" style={{position:"absolute", top:"3px", right:"0px"}} onClick={() => {
                        setActive2(false)
                    }}/>
                </div>
                <img src={g55} style={{maxWidth:"100%"}}/>
            </ImagePopUp>
            <ImagePopUp active={active5} setActive={setActive5}>
                <div style={{position:"relative"}}>
                    <img src={krest} className = "krest" style={{position:"absolute", top:"3px", right:"0px"}} onClick={() => {
                        setActive2(false)
                    }}/>
                </div>
                <img src={g66} style={{maxWidth:"100%"}}/>
            </ImagePopUp>
            <ImagePopUp active={active6} setActive={setActive6}>
                <div style={{position:"relative"}}>
                    <img src={krest} className = "krest" style={{position:"absolute", top:"3px", right:"0px"}} onClick={() => {
                        setActive2(false)
                    }}/>
                </div>
                <img src={g77} style={{maxWidth:"100%"}}/>
            </ImagePopUp>

            <ImagePopUp active={active8} setActive={setActive8}>
                <div style={{position:"relative"}}>
                    <img src={krest} className = "krest" style={{position:"absolute", top:"3px", right:"0px"}} onClick={() => {
                        setActive2(false)
                    }}/>
                </div>
                <img src={g22} style={{maxWidth:"100%"}}/>
            </ImagePopUp>

            <ImagePopUp active={active9} setActive={setActive9}>
                <div style={{position:"relative"}}>
                    <img src={krest} className = "krest" style={{position:"absolute", top:"3px", right:"0px"}} onClick={() => {
                        setActive2(false)
                    }}/>
                </div>
                <img src={g88} style={{maxWidth:"100%"}}/>
            </ImagePopUp>




            <ImagePopUp active={mobActive1} setActive={setActiveMob1}>
                <div style={{position:"relative"}}>
                    <img src={krest} className = "krest" style={{position:"absolute", top:"3px", right:"0px"}} onClick={() => {
                        setActiveMob1(false)
                    }}/>
                </div>
                <img src={gallmob1} style={{maxWidth:"100%", marginTop:"37px"}}/>
            </ImagePopUp>
            <ImagePopUp active={mobActive2} setActive={setActiveMob2}>
                <div style={{position:"relative"}}>
                    <img src={krest} className = "krest" style={{position:"absolute", top:"3px", right:"0px"}} onClick={() => {
                        setActiveMob2(false)
                    }}/>
                </div>
                <img src={gallmob2} style={{maxWidth:"100%", marginTop:"37px"}}/>
            </ImagePopUp>
            <ImagePopUp active={mobActive3} setActive={setActiveMob3}>
                <div style={{position:"relative"}}>
                    <img src={krest} className = "krest" style={{position:"absolute", top:"3px", right:"0px"}} onClick={() => {
                        setActiveMob3(false)
                    }}/>
                </div>
                <img src={gallmob3} style={{maxWidth:"100%", marginTop:"37px"}}/>
            </ImagePopUp>
            <ImagePopUp active={mobActive4} setActive={setActiveMob4}>
                <div style={{position:"relative"}}>
                    <img src={krest} className = "krest" style={{position:"absolute", top:"3px", right:"0px"}} onClick={() => {
                        setActiveMob4(false)
                    }}/>
                </div>
                <img src={gallmob4} style={{maxWidth:"100%", marginTop:"37px"}}/>
            </ImagePopUp>
            <ImagePopUp active={mobActive5} setActive={setActiveMob5}>
                <div style={{position:"relative"}}>
                    <img src={krest} className = "krest" style={{position:"absolute", top:"3px", right:"0px"}} onClick={() => {
                        setActiveMob5(false)
                    }}/>
                </div>
                <img src={gallmob5} style={{maxWidth:"100%", marginTop:"37px"}}/>
            </ImagePopUp>
            <ImagePopUp active={mobActive6} setActive={setActiveMob6}>
                <div style={{position:"relative"}}>
                    <img src={krest} className = "krest" style={{position:"absolute", top:"3px", right:"0px"}} onClick={() => {
                        setActiveMob6(false)
                    }}/>
                </div>
                <img src={gallmob6} style={{maxWidth:"100%", marginTop:"37px"}}/>
            </ImagePopUp>
            <ImagePopUp active={mobActive7} setActive={setActiveMob7}>
                <div style={{position:"relative"}}>
                    <img src={krest} className = "krest" style={{position:"absolute", top:"3px", right:"0px"}} onClick={() => {
                        setActiveMob7(false)
                    }}/>
                </div>
                <img src={gallmob7} style={{maxWidth:"100%", marginTop:"37px"}}/>
            </ImagePopUp>
            <ImagePopUp active={mobActive8} setActive={setActiveMob8}>
                <div style={{position:"relative"}}>
                    <img src={krest} className = "krest" style={{position:"absolute", top:"3px", right:"0px"}} onClick={() => {
                        setActiveMob8(false)
                    }}/>
                </div>
                <img src={gallmob8} style={{maxWidth:"100%", marginTop:"37px"}}/>
            </ImagePopUp>







            <Popup active={active} setActive={setActive}>
                <div style={{position:"relative"}}>
                <img src={krest} className = "krest" style={{position:"absolute", top:"3px", right:"0px"}} onClick={() => {
                    setActive(false)
                }}/>
                </div>
                <h3 style={{margin: "30px"}}>Замовити консультацію</h3>

                <form ref={form} onSubmit={sendEmail}>
                    <label>
                        <input style={{ width:"95%"}} placeholder = "Ваше імя" type="text" name="user" />
                    </label>
                    <label style={{display:"none"}}>
                        <input value = "gardentown2023@gmail.com" type="email" name="user_email" />
                    </label>
                    <label>
                        <input type = "text" style={{margin: "20px 0", width:"95%"}} value={number} name="message" onChange={(e) => {
                            setNumber(e.target.value)
                        }} />
                    </label>
                    <div style={{width:"95%"}}>
                        <label style={{fontSize:"13px"}}>
                            <input pattern="" type="checkbox" name="switch" className="check" onClick={(e) => {
                                document.querySelector(".opp").disabled = !e.currentTarget.checked;

                            }} onLoad={() => {
                                document.querySelector(".opp").disabled = true
                            }}/>
                            Натискаючи кнопку «Замовити дзвінок», ви даєте згоду на обробку персональних даних.
                        </label>
                    </div>
                    <div style={{display: "flex", justifyContent: "center", margin: "20px 0 0 0"}}>

                    <input type="submit" value="Замовити дзвінок" className="call_button opp" style={{marginBottom:"36px", display: "flex", justifyContent: "center",width:"95%"}} onClick={(e) => {
                        //sendEmail()
                        setActive(false)
                        setActive7(true)

                    }} />
                    </div>
                </form>

                <div className="backline" style={{
                    transform: "translate(-50%)",
                    marginLeft: "50%",
                    width: "95%", display:"grid", gridTemplateColumns:"1fr 1fr 1fr"}}><div><hr/></div><div style={{background: "white", color:"#A9A99D"}}>Або по телефону</div><div> <hr/></div>
                </div>
                <div style={{display: "flex", justifyContent: "center", color:"#1B9217"}}>
                    <button className="call_button mod"
                            style={{marginTop: "36px", display: "flex", justifyContent: "center", width:"95%"}}><img
                        src={cal} alt=""/> <a href="tel:+380507029798" style={{color:"#1B9217"}}>+38 (050) 702 97 98</a></button>
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <button className="call_button mod"
                            style={{marginBottom:"30px",marginTop: "15px", display: "flex", justifyContent: "center", width:"95%"}}><img
                        src={cal} alt=""/> <a href="tel:+380954461946" style={{color:"#1B9217"}}>+38 (095) 446 19 46</a></button>
                </div>

            </Popup>
            <div className="header">

                    <div className="main_block">
                        <a href="/">

                        <img src={Logo} alt="home"/>
                        </a>
                    </div>

                <div className="main_menu">
                <span>
                    <a href="/#about">Про нас</a>
                </span>
                    <span>
                    <a href="/#perev">Переваги</a>
                </span>
                    <span>
                    <a href="/#flat">Планування</a>
                </span>
                    <span>
                    <a href="/#gallery">Галерея</a>
                </span>
                    <span>
                    <a href="/#progress">Хід будівництва</a>
                </span>
                    <span>
                    <a href="/#faq">Часті запитання</a>
                </span>
                    <span>
                    <a href="/#contacts">Контакти</a>
                </span>
                </div>
                <div className="header_call_button">
                    <button className="call_button" onClick={() => setActive(true)}><img src={call} alt=""/> Замовити
                        дзвінок
                    </button>
                </div>

                    <div className="menu-btn" onClick={() => {
                        setActiv1(true)
                    }}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>

                <Popup active={activ1} setActive={setActiv1}>
                <div className="main_block" style={{marginBottom:"20px", position:"relative"}}>
                    <img src={Logo} alt="home" className = "qqw"/>
                    <img src={krest} className = "krest" style={{position:"absolute", top:"3px", right:"0px"}} onClick={() => {
                       setActiv1(false)
                    }}/>
                </div>
                    <div className = "mobile_menu" style={{textAlign:"left"}}>
                        <div>
                            <div style={{color:"#A9A99D", fontSize:"10px"}}>Навігація</div>
                            <div>
                                <a href="/#about" onClick={() => {
                                    setActiv1(false)
                                }}>Про нас</a>
                            </div>
                            <div>
                                <a href="/#perev" onClick={() => {
                                    setActiv1(false)
                                }}>Переваги</a>
                            </div>
                            <div>
                                <a href="/#flat" onClick={() => {
                                    setActiv1(false)
                                }}>Планування</a>
                            </div>
                            <div>
                                <a href="/#gallery" onClick={() => {
                                    setActiv1(false)
                                }}>Галерея</a>
                            </div>
                            <div>
                                <a href="/#progress" onClick={() => {
                                    setActiv1(false)
                                }}>Хід будівництва</a>
                            </div>
                            <div>
                                <a href="/#faq" onClick={() => {
                                    setActiv1(false)
                                }}>Часті запитання</a>
                            </div>
                            <div>
                                <a href="/#contacts" onClick={() => {
                                    setActiv1(false)
                                }}>Контакти</a>
                            </div>
                        </div>
                        <div>
                            <div style={{color:"#A9A99D", fontSize:"10px"}}>Контакти</div>

                            <p style={{marginTop: "8px"}}><a href="tel:+380507029798">+38 (050) 702 97 98</a></p>
                            <p style={{marginTop: "8px"}}><a href="tel:+380954461946">+38 (095) 446 19 46</a></p>
                            <div style={{color:"#A9A99D", fontSize:"10px"}}>Пошта</div>
                            <p style={{marginTop: "10px",fontWeight:"bold"}}><a href="mailto:gardentown2023@gmail.com">gardentown2023@gmail.com</a></p>
                        </div>
                        <button className="call_button" onClick={() => setActive(true)} style={{marginTop:"20px", display: "flex", fontWeight: "bold", justifyContent: "center"}}>
                            <img src={call} alt="" style={{width:"24px"}}/>
                            Замовити дзвінок
                        </button>
                    </div>
            </Popup>
            </div>
            <div className="main_banner_block">
                <div className = "qw"></div>
                <div className="wsx">
                    <div className = "blur" style={{height:"auto"}}>
                        ТВІЙ НОВИЙ ДІМ
                    </div>
                    <div className = "under_blur">
                        Garden town
                    </div>
                </div>
                <div className = "ppq" style={{borderRadius:"20px", width:"100%", height:"100vh"}}>
                <Slider {...settings6} >
                    <div>
                        <img src={test} style={{width:"100%",objectFit:"cover"}}/>
                    </div>
                    <div>
                        <img src={ban2} alt="" style={{width:"100%",objectFit:"cover"}}/>
                    </div>
                    <div>
                        <img src={ban3} alt="" style={{width:"100%",objectFit:"cover"}}/>
                    </div>
                </Slider>
            </div>

            </div>


            <div className="ab_company" >
                <div>
                    <img src={window.innerWidth < 480 ? p7 : p2}/>
                </div>
                <div className = "ab_company_info">
                    <a name="about"></a>
                    <h2>
                        Garden Town це:
                    </h2>
                    <p>
                        Це не просто заміський будинок - це ваш особистий простір, де ви зможете знайти спокій та затишок
                        після напруженого робочого дня. Це дім із затишним двориком, ідеальним місцем як для сімейного
                        барбекю, так і для ранкової кави.
                    </p>
                    <p>
                        Завдяки вдалому плануванню та функціональним просторам, разом з комплексом “Garden Town” ви будете
                        насолоджуватись родинними зустрічами, відпочинком із близькими та створенням незабутніх спогадів.</p>
                    <p>
                        Garden Town пропонує комбінацію пішої доступності до магазинів, садочків та інших зручностей, не
                        втрачаючи при цьому заміського затишку і приватності, залишаючись всього в 3х км від Києва.
                    </p>
                    <div className="call_block" style={{marginTop: "40px", justifyContent:"start"}}>
                        <button className="call_button" onClick={() => setActive(true)} style={{marginTop:"20px"}}><img src={call} alt="" style={{width:"24px"}}/>
                            Замовити дзвінок
                        </button>
                    </div>
                </div>
            </div>


            <div className="perev">
                <div>
                    <a name="perev"><img src={window.innerWidth < 480 ? p6 : rect2} alt=""/></a>
                    <h3 style={{width:"100%", position:"relative"}}>Зручне розташування<span style={{position:"absolute", right:"0", color: "var(--Gray, #A9A99D)"}}>01</span></h3>
                    <p className = "w75">
                        Таунхаус розташований в жилому районі міста Вишневе, та ви можете з легкістю дістатися до центру
                        міста Києва за 15 хвилин на автомобілі, зберігаючи при цьому відстань від його шуму та метушні.
                        Ви можете насолоджуватися заміським затишком та одночасно мати зручний доступ до всіх установ та
                        послуг міста.
                    </p>
                </div>
                <div>
                    <img src={window.innerWidth < 480 ? p5 : p4} alt=""/>
                    <h3 style={{width:"100%", position:"relative"}}>Просторий підвал<span style={{position:"absolute", right:"0", color: "var(--Gray, #A9A99D)"}}>02</span></h3>
                    <p style={{marginBottom: "35px"}}>Ми пропонуємо Вам дім з просторим підвалом, що додає додаткові можливості для зберігання та
                        розширення простору. Ви матимете можливість використовувати підвал для зберігання сезонних
                        речей, спортивного обладнання або навіть створити додаткову кімнату для розваг або роботи.
                        Підвал додає багато цінної площі і дозволяє максимально ефективно використовувати простір вашого
                        таунхаусу.</p>
                </div>
            </div>

            <div className="perev2">
                <div>
                    <img src={window.innerWidth < 480 ? p3 : rect4} alt=""/>

                    <h3 style={{width:"100%", position:"relative"}}>Особисте паркомісце<span style={{position:"absolute", right:"0", color: "var(--Gray, #A9A99D)"}}>03</span></h3>
                    <p>
                        Крім того, Garden Town пропонує Вам особисте паркомісце, що робить його ще більш зручним для
                        вас.</p><p>Ви можете бути впевнені, що у вас завжди буде місце для паркування свого автомобіля
                    біля вашого будинку. Наш таунхаус з особистим паркомісцем - це ідеальне рішення для вашої сім’ї.
                </p>
                </div>
                <div>
                    <img src={window.innerWidth < 480 ? p8 :rect5} alt=""/>
                    <h3 style={{width:"100%", position:"relative"}}>Дворик з терасою<span style={{position:"absolute", right:"0", color: "var(--Gray, #A9A99D)"}}>04</span></h3>
                    <p>
                        Наш чудовий таунхаус також має огороджений дворик з терасою, що надає додаткову приватність та
                        можливість насолоджуватися затишком на вулиці. Ви можете проводити час з родиною та друзями на
                        терасі, влаштовувати барбекю або просто відпочити на свіжому повітрі. </p><p>Огороджений дворик
                    також надає безпечне місце для гри дітям або для ваших домашніх улюбленців. Наш таунхаус з
                    огородженим двориком та терасою стане ідеальним місцем для створення затишної атмосфери у вашому
                    домі.</p>
                </div>
            </div>
            <div className="full-width">
                <img src={n1} alt=""/>
                <h3 style={{width:"100%", position:"relative", margin: "10px 0"}}>Повністю закрита територія<span style={{position:"absolute", right:"0", color: "var(--Gray, #A9A99D)"}}>05</span></h3>
                <p style={{width: "70%"}}>
                    Територія Garden Town є закритою з системою контролю доступу (СКД), а також встановлено загальне
                    відеоспостереження. <br/>Це означає, що лише мешканці та їх гості можуть потрапити на нашу
                    територію, забезпечуючи високий рівень безпеки та приватності. Ви можете бути спокійні, знаючи, що
                    ваша сім'я та ваші цінності захищені від небажаних осіб. Така система контролю доступу додає ще
                    більше спокою та комфорту до вашого життя в нашому таунхаусі.
                </p>
            </div>
            <div className = "mobile_slider" style = {{marginTop:"20px"}}>
            <Slider {...settings3}>
                <div >
                    <img src={rect7} alt="" style={{width: "100%"}}/>
                    <h3 style={{width:"95%", position:"relative", margin: "10px 0"}}>Фасад<span style={{position:"absolute", right:"0", color: "var(--Gray, #A9A99D)"}}>06</span></h3>
                    <p style={{width:"80% !important"}}>
                        Виконаний по системі утеплення ЦЕРЕЗІТ, утеплювач товщиною 100мм. армований лугостійкою,
                        фасадною сіткою щільністю 160 г/м2
                    </p>
                </div>
                <div>
                    <img src={rect8} alt="" style={{width: "100%"}}/>
                    <h3 style={{width:"95%", position:"relative", margin: "10px 0"}}>Прибудинкова територія<span style={{position:"absolute", right:"0", color: "var(--Gray, #A9A99D)"}}>07</span></h3>
                    <p>
                        Наша прибудинкова територія облаштована тротуарною плиткою «Золотий Мандарин», що надає їй
                        елегантний та стильний вигляд.
                    </p>
                </div>
                <div>
                    <img src={rect9} alt="" style={{width: "100%"}}/>

                    <h3 style={{width:"95%", position:"relative", margin: "10px 0"}}>Опалення<span style={{position:"absolute", right:"0", color: "var(--Gray, #A9A99D)"}}>08</span></h3>
                    <p>
                        Індивідуальні газові двоконтурні котли.
                    </p>
                </div>

            </Slider>
            </div>
            <div className="about_bud">
                <div>
                    <img src={rect7} alt=""/>
                    <h3 style={{width:"100%", position:"relative", margin: "10px 0"}}>Фасад<span style={{position:"absolute", right:"0", color: "var(--Gray, #A9A99D)"}}>06</span></h3>
                    <p>
                        Виконаний по системі утеплення ЦЕРЕЗІТ, утеплювач товщиною 100мм. армований лугостійкою,
                        фасадною сіткою щільністю 160 г/м2
                    </p>
                </div>
                <div>
                    <img src={rect8} alt=""/>
                    <h3 style={{width:"100%", position:"relative", margin: "10px 0"}}>Прибудинкова територія<span style={{position:"absolute", right:"0", color: "var(--Gray, #A9A99D)"}}>07</span></h3>
                    <p>
                        Наша прибудинкова територія облаштована тротуарною плиткою «Золотий Мандарин», що надає їй
                        елегантний та стильний вигляд.
                    </p>
                </div>
                <div>
                    <img src={rect9} alt=""/>
                    <h3 style={{width:"100%", position:"relative", margin: "10px 0"}}>Опалення<span style={{position:"absolute", right:"0", color: "var(--Gray, #A9A99D)"}}>08</span></h3>
                    <p>
                        Індивідуальні газові двоконтурні котли.
                    </p>
                </div>
            </div>
            <div className="call_block" style={{marginTop: "40px"}}>
                <button className="call_button" onClick={() => setActive(true)}><img src={call} alt=""/> Замовити
                    дзвінок
                </button>
            </div>
            <div className="plaz">$ 650 m²</div>
            <div className="flat" onClick={() => {

            }}>

                <div className = "pp" >
                    <a name="flat">
                        <h2 className = "qwert" style={{width:"100%", position:"relative"}}>Планування
                            <div className = "plaz2">$ 650 m²</div>
                            <span className = "variant_flat" style={{ right:"0", display:"flex"}}>
                            <div className = "mar_fon" style={{cursor:"pointer", height:"1em", color: "var(--Gray, #A9A99D)", display:"flex", alignItems:"center"}} onClick={() => {setPlan(1)
                                let c = document.querySelector(".mar_fon")
                                let b = document.querySelector(".mar_fon1")
                                c.classList.remove("black")
                                b.classList.add("black")}}>
                                <div style={{minWidth:"1em", minHeight:"1em",background:"rgba(58, 58, 31, 0.20)", padding:"10px", borderRadius:"50%", marginRight:"20px", display:"flex", alignItems:"center", justifyContent:"center"}}>
                                    <img src={leftArrowB} style={{height:"100%", width:"100%"}}/>
                                </div>
                                1 варіант
                            </div>
                            <div className = "mar_fon1 black"
                                style={{cursor:"pointer", height:"1em", color: "var(--Gray, #A9A99D)", fontSize:"20px", display:"flex", alignItems:"center"}}  onClick={(ev) => {setPlan(2)
                                let b = document.querySelector(".mar_fon1")
                                let c = document.querySelector(".mar_fon")
                                c.classList.add("black")
                                b.classList.remove("black")
                                }
                                                        }>
                                2 варіант
                                <div style={{minWidth:"1em", padding:"10px" , minHeight:"1em",background:"rgba(58, 58, 31, 0.20)", borderRadius:"50%", marginLeft:"20px", display:"flex", alignItems:"center", justifyContent:"center"}}>
                                    <img src={rightArrowB}/>
                                </div>
                            </div>
                                </span>
                        </h2>
                    </a>

                </div>
                <div className = "mod_n">
                    {/*<Slider {...settings} >
                    <div style={{background:"#d2d4d3"}} className = "zak">
                        <div className = "dat_bud" >1 ПОВЕРХ</div>
                        <img src={plan === 1 ? pov1 : pov12} alt="" />
                    </div>
                    <div className = "zak2">
                        <div className = "dat_bud">РОЗМІРИ</div>
                        <img src={plan === 1 ? pov1r : qqww} alt=""  style={{width: "100%"}}/>
                    </div>
                    <div className = "zak">
                        <div className = "dat_bud">2 ПОВЕРХ</div>
                        <img src={plan === 1 ? pov2 : pov32} alt=""/>
                    </div>
                    <div className = "zak2">
                        <div className = "dat_bud">РОЗМІРИ</div>
                        <img src={plan === 1 ? pov2r : pov32r} alt="" style={{width: "100%", transform:plan === 1 ? "rotate(180deg)" : ""}}/>
                    </div>

                    <div className = "zak">
                        <div className = "dat_bud">ПІДВАЛ</div>
                        <img src={plan === 1 ? pov3 : pov22} alt=""/>
                    </div>
                    <div className = "zak2">
                        <div className = "dat_bud">РОЗМІРИ</div>
                        <img src={plan === 1 ? pov3r : pov22r} alt="" style={{width: "100%"}}/>
                    </div>
                </Slider>*/}
                    <Slider {...settings8}>
                        <div className = "flat_1f">
                            <div className="for_pl">
                                <div><div className = "dat_bud" >1 ПОВЕРХ</div></div>
                                <div><div className = "dat_bud" >РОЗМІРИ</div></div>
                            </div>
                            <img src={plan === 1 ? pov1v1 : povvv1v2} />
                        </div>
                        <div className = "flat_1f">
                            <div className="for_pl">
                                <div><div className = "dat_bud" >2 ПОВЕРХ</div></div>
                                <div><div className = "dat_bud" >РОЗМІРИ</div></div>
                            </div>
                            <img src={plan === 1 ? pov2v1 : et2v22}/>
                        </div>
                        <div className = "flat_1f">
                            <div className="for_pl">
                                <div><div className = "dat_bud" >ПІДВАЛ</div></div>
                                <div><div className = "dat_bud" >РОЗМІРИ</div></div>
                            </div>
                            <img src={plan === 1 ? podv1v : podvvv2}/>
                        </div>

                    </Slider>
                </div>
                <div className = "mob_flat">
                    <Slider {...settings4}>
                        <div style={{position:"relative"}}>
                            <div className = "dat_bud">1 ПОВЕРХ</div>
                            <img src = {plan === 1 ? et1v1m : et1v2m}/>
                        </div>
                        <div style={{position:"relative"}}>
                            <div className = "dat_bud">2 ПОВЕРХ</div>
                            <img src = {plan === 1 ? et2v1m : et2v2m}/>
                        </div>
                        <div style={{position:"relative"}}>
                            <div className = "dat_bud">ПІДВАЛ</div>
                            <img src = {plan === 1 ? podv1m : podv2m}/>
                        </div>
                    </Slider>
                </div>


            </div>
            <h2 className = "wer" style={{margin:"20px 0 0 10px"}}>
                Галерея
            </h2>
            <a name="gallery"></a>

            <div className="main_gallery">
                <div className="gallery" style={{marginTop: "70px"}}>

                    <img src={g22} alt="" className="img1" onClick={() => {
                        setActive8(true)
                    }}/>
                    <img src={g33} alt="" className="img2" onClick={() => {
                        setActive1(true)
                    }}/>
                    <img src={g11} alt="" className="img3" onClick={() => {
                        setActive2(true)
                    }}/>
                    <img src={g44} alt="" className="img4" onClick={() => {
                        setActive3(true)
                    }}/>


                </div>

                <div className="gallery1">

                    <img src={g55} alt="" className="img5" onClick={() => {
                        setActive4(true)
                    }}/>
                    <img src={g66} alt="" className="img6" onClick={() => {
                        setActive5(true)
                    }}/>
                    <img src={g77} alt="" className="img7" onClick={() => {
                        setActive6(true)
                    }}/>
                    <img src={g88} alt="" className="img8" onClick={() => {
                        setActive9(true)
                    }}/>

                </div>


            </div>

            <motion.div  className = "mob_gallery" style = {{marginTop:"25px"}}>
                <Slider {...settings5} >
                    <div>
                        <img src={gallmob1} alt="" style={{width: "90%"}} onClick={() => {
                            setActiveMob1(true)
                        }}/>
                    </div>
                    <div>
                        <img src={gallmob2} alt="" style={{width: "90%"}} onClick={() => {
                            setActiveMob2(true)
                        }}/>
                    </div>
                    <div>
                        <img src={gallmob3} alt="" style={{width: "90%"}} onClick={() => {
                            setActiveMob3(true)
                        }}/>
                    </div>
                    <div>
                        <img src={gallmob4} alt="" style={{width: "90%"}} onClick={() => {
                            setActiveMob4(true)
                        }}/>
                    </div>

                    <div>
                        <img src={gallmob5} alt="" style={{width: "90%"}} onClick={() => {
                            setActiveMob5(true)
                        }}/>
                    </div>
                    <div>
                        <img src={gallmob6} alt="" style={{width: "90%"}} onClick={() => {
                            setActiveMob6(true)
                        }}/>
                    </div>
                    <div>
                        <img src={gallmob7} alt="" style={{width: "90%"}} onClick={() => {
                            setActiveMob7(true)
                        }}/>
                    </div>
                    <div>
                        <img src={gallmob8} alt="" style={{width: "90%"}} onClick={() => {
                            setActiveMob8(true)
                        }}/>
                    </div>
                </Slider>
            </motion.div>
            <div className="progress" >
                <div className="progress_head">
                    <div><a name="progress"></a>
                        <h2 style={{margin: "48px 10px"}}>Хід будівництва</h2>
                    </div>
                    <div className="prog_reg" >
                        <div><img src={arrow} alt="" style={{width: "48px"}}/>Попередні</div>
                        <div style={{marginLeft: "20px"}}>Наступні<img src={arrow1} alt="" style={{width: "48px"}}/>
                        </div>
                    </div>
                </div>
                <div className="gallery_slider mod_n">


                    <Slider {...settings7} >
                        <div style = {{position:"relative", padding:"12px"}} >
                            <div className = "date_bud">03.07.2023</div>
                            <img src={rect19} alt=""/>
                        </div>
                        <div style = {{position:"relative", padding:"12px"}}>
                            <div className = "date_bud">02.08.2023</div>
                            <img src={rect20} alt="" />
                        </div>
                        <div style = {{position:"relative", padding:"12px"}}>
                            <div className = "date_bud">08.08.2023</div>
                            <img src={rect21} alt="" />
                        </div>
                        <div style = {{position:"relative", padding:"12px"}}>
                            <div className = "date_bud">14.09.2023</div>
                            <img src={newbuddesk} alt="" style={{height:"100%"}}/>
                        </div>

                    </Slider>
                </div>
                <div className="gallery_slider mob_y qws" >


                    <Slider {...settings5} >
                        <div style = {{position:"relative"}}>
                            <div className = "date_bud">03.07.2023</div>
                            <img src={rect19} alt=""/>
                        </div>
                        <div style = {{position:"relative"}}>
                            <div className = "date_bud">02.08.2023</div>
                            <img src={rect20} alt="" style={{maxHeight:"100%"}}/>
                        </div>
                        <div style = {{position:"relative"}}>
                            <div className = "date_bud">08.08.2023</div>
                            <img src={rect21} alt="" />
                        </div>
                        <div style = {{position:"relative"}}>
                            <div className = "date_bud">14.09.2023</div>
                            <img src={newbud} alt=""/>
                        </div>

                    </Slider>
                </div>
                <div className="call_block" style={{marginTop: "30px"}}>
                    <a href = "https://t.me/gardentown2023"><button className="call_button"><img src={tg} alt=""/> Слідкувати в телеграмі</button></a>
                </div>
            </div>
            <div className = "sdc">
                <a name="faq"></a><h2 style={{fontSize: "40px", marginTop:"20px"}}>Часті запитання</h2>
                <div className="faq_block">
                    <div className="faq_question">
                        <div onClick={() => {
                            let y = document.querySelector(".q1")
                            let o = document.querySelector(".fp1")
                            o.classList.toggle("rotate_plus")
                            y.classList.toggle("p")
                            y.classList.toggle("po")
                        }}>Якою є загальна площа таунхаусів? <img src={plus} className="faq_plus fp1" alt=""/></div>
                        <div className="q q1"> Ви маєте можливість обрати будинок, що відповідає вашим потребам та вимогам. Ми пропонуємо вам будинки площею 117м2, або площею 235м2
                        </div>
                        <hr style = {{width:"100%", background:"lightgray"}}/>
                    </div>
                    <div className="faq_question">
                        <div onClick={() => {
                            let y = document.querySelector(".q2")
                            let o = document.querySelector(".fp2")
                            o.classList.toggle("rotate_plus")
                            y.classList.toggle("p")
                        }}>Чи є на території таунхаусів місця для паркування авто? <img src={plus}
                                                                                        className="faq_plus fp2"
                                                                                        alt=""/></div>
                        <div className="q q2"> Garden Town пропонує Вам особисте паркомісце, тому Ви можете бути
                            впевнені, що у вас завжди буде місце для паркування свого автомобіля прямо біля вашого
                            будинку.
                        </div>
                        <hr style = {{width:"100%", background:"lightgray"}}/>
                    </div>
                    <div className="faq_question">
                        <div onClick={() => {
                            let y = document.querySelector(".q3")
                            let o = document.querySelector(".fp3")
                            o.classList.toggle("rotate_plus")
                            y.classList.toggle("p")
                        }}>Яка є відстань від Києва? <img src={plus} className="faq_plus fp3" alt=""/></div>
                        <div className="q q3"> Розташовані всього в 3 км від Києва та 15 км від центру міста - Майдан
                            Незалежності.
                        </div>
                        <hr style = {{width:"100%", background:"lightgray"}}/>
                    </div>
                    <div className="faq_question">
                        <div onClick={() => {
                            let y = document.querySelector(".q4")
                            let o = document.querySelector(".fp4")
                            o.classList.toggle("rotate_plus")
                            y.classList.toggle("po")
                            y.classList.toggle("p")
                        }}>Чи є в районі розташування таунхаусу магазини, школи, садочки? <img src={plus}
                                                                                               className="faq_plus fp4"
                                                                                               alt=""/></div>
                        <div className="q q4"> Таунхаус знаходиться в дуже зручному місці з пішою доступністю до
                            садочків, шкіл, магазинів, супермаркетів та ринку. Всі ці об'єкти знаходяться поруч і можуть
                            бути досягнуті за 5-10 хвилин пішки. Це робить розташування таунхаусу дуже зручним для життя
                            та забезпечує легкий доступ до необхідних послуг та установ.
                        </div>
                        <hr style = {{width:"100%", background:"lightgray"}}/>
                    </div>
                    <div className="faq_question">
                        <div onClick={() => {

                            let y = document.querySelector(".q5")
                            let o = document.querySelector(".fp5")
                            o.classList.toggle("rotate_plus")
                            y.classList.toggle("p")
                        }}>Яка транспортна розв'язка доступна поруч з таунхаусом? <img src={plus}
                                                                                       className="faq_plus fp5" alt=""/>
                        </div>
                        <div className="q q5"> Поруч є автобусні зупинки та вокзал. На автомобілі 12-15 хвилин до метро
                            Теремки
                        </div>
                        <hr style = {{width:"100%", background:"lightgray"}}/>
                    </div>
                    <div className="faq_question">
                        <div onClick={() => {
                            let y = document.querySelector(".q6")
                            let o = document.querySelector(".fp6")
                            o.classList.toggle("rotate_plus")
                            y.classList.toggle("p")
                            y.classList.toggle("pop")
                        }}>Які можливості існують для індивідуального оформлення будинків? <img src={plus}
                                                                                                className="faq_plus fp6"
                                                                                                alt=""/></div>
                        <div className="q q6"> Ви маєте можливість вибрати будинок, що відповідає вашим потребам та
                            вимогам, а також брати участь у процесі індивідуального оформлення внутрішнього простору. Ми
                            допоможемо створити вашу унікальну атмосферу та зробити будинок під ваш особистий комфорт.
                        </div>
                        <hr style = {{width:"100%", background:"lightgray"}}/>
                    </div>
                    <div className="faq_question">
                        <div onClick={() => {
                            let y = document.querySelector(".q7")
                            let o = document.querySelector(".fp7")
                            o.classList.toggle("rotate_plus")
                            y.classList.toggle("pop")
                            y.classList.toggle("p")
                        }}>Як можу отримати додаткову інформацію та організувати огляд будинків? <img src={plus}
                                                                                                      className="faq_plus fp7"
                                                                                                      alt=""/></div>
                        <div className="q q7"> Щоб отримати додаткову інформацію та організувати огляд будинків, ви
                            можете зв’язатися з нашим відділом продажу, залишивши запит на нашому сайті або
                            зателефонувавши за вказаним номером. Наші фахівці з радістю нададуть вам всю необхідну
                            інформацію та домовляться про зручний для вас час огляду.
                        </div>
                        <hr style = {{width:"100%", background:"lightgray"}}/>
                    </div>
                </div>
            </div>
            <a name = "contacts"></a>
            <div className = "contacts_mobile_main">
            <div className = "contacts_mobile">
                <div>
                    <h2 className = "qwers">
                        Контакти
                    </h2>
                    <h3 >Адреса будинку</h3>
                    <p >
                        м. Вишневе,  вул. Садова, 4
                    </p>
                    <h3 style={{marginTop: "20px"}}>
                        Номер телефону
                    </h3>
                    <p style={{marginTop: "12px"}}>+38 (050) 702 97 98</p>
                    <p style={{marginTop: "12px"}}>+38 (095) 446 19 46</p>
                </div>
                <div>

                    <h3 style={{marginTop: "20px"}}>Поштова скринька</h3>
                    <p style={{marginTop: "10px"}}>gardentown2023@gmail.com</p>
                    <h3 style={{marginTop: "20px"}}>Графік роботи</h3>
                    <p style={{marginTop: "10px"}}>
                        Пн – Пт: 09:00-19:00;</p><p>Сб – Нд: 09:00–18:00
                    </p>

                </div>
            </div>
                <div>
                    <iframe
                        style={{width: "100%", height: "100%", minHeight:"80vh", marginTop:"25px"}}
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2544.0052342302324!2d30.372870876498638!3d50.38510097157986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4ca28e2b823f7%3A0x5734cae16d3d19c8!2z0YPQuy4g0KHQsNC00L7QstCw0Y8sIDQsINCS0LjRiNC90LXQstC-0LUsINCa0LjQtdCy0YHQutCw0Y8g0L7QsdC70LDRgdGC0YwsIDA4MTMy!5e0!3m2!1sru!2sua!4v1694360359555!5m2!1sru!2sua"
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div>
                    <button onClick={() => setActive(true)} className="call_button" style={{width:"100%", display:"flex", justifyContent:"center"}}><img src={tg} alt=""/> Замовити консультацію</button>
                </div>
            </div>
            <div className="contacts">
                <div>
                    <a name="contacts"><h2 >
                        Контакти
                    </h2></a>
                    <h3 style={{marginTop: "20px"}}>Адреса будинку</h3>
                    <p style={{marginTop: "10px"}}>
                        м. Вишневе,  вул. Садова, 4
                    </p>
                    <h3 style={{marginTop: "20px"}}>
                        Номер телефону
                    </h3>
                    <p style={{marginTop: "8px"}}>+38 (050) 702 97 98</p>
                    <p style={{marginTop: "8px"}}>+38 (095) 446 19 46</p>
                    <h3 style={{marginTop: "20px"}}>Поштова скринька</h3>
                    <p style={{marginTop: "10px"}}>gardentown2023@gmail.com</p>
                    <h3 style={{marginTop: "20px"}}>Графік роботи</h3>
                    <p style={{marginTop: "10px"}}>
                        Пн – Пт: 09:00-19:00
                    </p>
                    <p style={{marginTop: "10px"}}>
                        Сб – Нд: 09:00–18:00
                    </p>
                    <div style={{marginTop: "40px"}}>
                        <button className="call_button" onClick={() => setActive(true)}><img src={call} alt=""/> Замовити консультацію</button>
                    </div>
                </div>
                <div style={{padding: "20px"}}>
                    <iframe
                        style={{width: "100%", height: "100%"}}
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2544.0052342302324!2d30.372870876498638!3d50.38510097157986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4ca28e2b823f7%3A0x5734cae16d3d19c8!2z0YPQuy4g0KHQsNC00L7QstCw0Y8sIDQsINCS0LjRiNC90LXQstC-0LUsINCa0LjQtdCy0YHQutCw0Y8g0L7QsdC70LDRgdGC0YwsIDA4MTMy!5e0!3m2!1sru!2sua!4v1694360359555!5m2!1sru!2sua"
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            <div><a href="/">
                <div className="footer_main desk">
                    <img src={home} alt="home"/>
                    <h1 style = {{marginLeft: "20px"}}>Garden town</h1>
                </div>
            </a></div>
            <div className="footer">

                <a href="/" className ="desk">
                    <div className="footer_main">
                        <img src={home} alt="home"/>
                        <h1>Garden town</h1>
                    </div>
                </a>
                <div>
                    <div style={{marginBottom: "10px", fontSize: "18px"}}>
                        <b> Розділи сайту</b>
                    </div>
                    <div>
                        <a href="/#about">Про нас</a>
                    </div>
                    <div>
                        <a href="/#perev">Переваги</a>
                    </div>
                    <div>
                        <a href="/#flat">Планування</a>
                    </div>
                    <div>
                        <a href="/#gallery">Галерея</a>
                    </div>
                    <div>
                        <a href="/#progress">Хід будівництва</a>
                    </div>
                    <div>
                        <a href="/#faq">Часті запитання</a>
                    </div>
                    <div>
                        <a href="/#contacts">Контакти</a>
                    </div>
                </div>

                <div>
                    <div style={{marginBottom: "10px", fontSize: "18px"}}>
                        <b> Контакти</b>
                    </div>
                    <div>
                        <a href="tel:+380954461946">+38 (095) 446 19 46</a>
                    </div>
                    <div>
                        <a href="tel:+380507029798">+38 (050) 702 97 98</a>
                    </div>

                    <div>
                        <a href="mailto:gardentown2023@gmail.com">gardentown2023@gmail.com</a>
                    </div>
                </div>
                <div>
                    <div style={{marginBottom: "10px", position:"relative"}}>
                        <b>Дивитись хід будівництва</b>
                       </div>
                    <div>
                        <a href = "https://t.me/gardentown2023">Telegram</a>
                    </div>

                </div>
                <div>

                     </div>
            </div>

            <div style={{fontSize:"12px", color:"#A9A99D"}}>© 2023 Garden town. All rights reserved.</div>

            {window.scrollY === 0 ? "" : <a href="/#top">
            <button style={{position:"fixed",bottom:"30px", right:"10px", background:"#1B9217", borderRadius:"50%", border:"none", transform:"rotate(90deg)", width:"30px", height:"30px", display:"flex", justifyContent:"center", alignItems:"center",zIndex:"10000"}}><img src={leftArrow}/></button></a>

            }


        </div>
    );
}

export default App;
